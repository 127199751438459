import BaseLayout from './components/BaseLayout';
import './App.module.scss';


function App() {

  return (
    <div>
      <BaseLayout/>
    </div>
  );
}

export default App;
